define("discourse/plugins/chat/discourse/components/chat/message-creator/list-action", ["exports", "discourse/components/d-button", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _dButton, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ListAction = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <DButton
      class="btn btn-flat"
      @icon={{@item.icon}}
      @translatedLabel={{@item.label}}
    />
  
  */
  {
    "id": "dRreNzv3",
    "block": "[[[1,\"\\n  \"],[8,[32,0],[[24,0,\"btn btn-flat\"]],[[\"@icon\",\"@translatedLabel\"],[[30,1,[\"icon\"]],[30,1,[\"label\"]]]],null],[1,\"\\n\"]],[\"@item\"],false,[]]",
    "moduleName": "/data/www/htdocs/aihub/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message-creator/list-action.js",
    "scope": () => [_dButton.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = ListAction;
});