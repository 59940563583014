define("discourse/plugins/chat/discourse/components/chat/message-creator/members", ["exports", "@glimmer/component", "@ember/component", "@ember/modifier", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "truth-helpers", "discourse-common/helpers/d-icon", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/message-creator/lib/iterate-list", "discourse/plugins/chat/discourse/components/chat/message-creator/member", "@ember/template-factory"], function (_exports, _component, _component2, _modifier, _object, _didInsert, _truthHelpers, _dIcon, _discourseI18n, _iterateList, _member, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Members extends _component.default {
    addMoreMembersLabel = (() => _discourseI18n.default.t("chat.new_message_modal.user_search_placeholder"))();
    onFilter() {
      this.args.onFilter(...arguments);
    }
    static #_ = (() => dt7948.n(this.prototype, "onFilter", [_object.action]))();
    registerFocusFilterAction(element1) {
      this.args.registerFocusFilterAction(() => element1.focus());
    }
    static #_2 = (() => dt7948.n(this.prototype, "registerFocusFilterAction", [_object.action]))();
    handleKeypress(event1) {
      if (event1.key === "Backspace" && event1.target.value === "") {
        event1.preventDefault();
        event1.stopPropagation();
        if (!this.args.highlightedMember) {
          this.args.onHighlightMember(this.args.members.lastObject);
        } else {
          this.args.onSelectMember(this.args.highlightedMember);
        }
        return;
      }
      if (event1.key === "ArrowLeft" && event1.target.value === "") {
        event1.preventDefault();
        event1.stopPropagation();
        this.args.onHighlightMember((0, _iterateList.getPrevious)(this.args.members, this.args.highlightedMember));
        return;
      }
      if (event1.key === "ArrowRight" && event1.target.value === "") {
        event1.preventDefault();
        event1.stopPropagation();
        this.args.onHighlightMember((0, _iterateList.getNext)(this.args.members, this.args.highlightedMember));
        return;
      }
      if (event1.key === "Enter" && this.args.highlightedMember) {
        event1.preventDefault();
        event1.stopPropagation();
        this.args.onSelectMember(this.args.highlightedMember);
        return;
      }
      this.highlightedMember = null;
    }
    static #_3 = (() => dt7948.n(this.prototype, "handleKeypress", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="chat-message-creator__members-container">
          <div class="chat-message-creator__members">
            {{icon "magnifying-glass"}}
    
            {{#each @members as |member|}}
              <Member
                @member={{member}}
                @onSelect={{@onSelectMember}}
                @highlighted={{eq member @highlightedMember}}
              />
            {{/each}}
    
            <Input
              placeholder={{this.addMoreMembersLabel}}
              class="chat-message-creator__members-input"
              @value={{@filter}}
              autofocus={{true}}
              {{on "input" this.onFilter}}
              {{on "keydown" this.handleKeypress}}
              {{didInsert this.registerFocusFilterAction}}
            />
          </div>
        </div>
      
    */
    {
      "id": "L8+tP4if",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-message-creator__members-container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"chat-message-creator__members\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"magnifying-glass\"],null]],[1,\"\\n\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1]],null]],null],null,[[[1,\"          \"],[8,[32,1],null,[[\"@member\",\"@onSelect\",\"@highlighted\"],[[30,2],[30,3],[28,[32,2],[[30,2],[30,4]],null]]],null],[1,\"\\n\"]],[2]],null],[1,\"\\n        \"],[8,[32,3],[[16,\"placeholder\",[30,0,[\"addMoreMembersLabel\"]]],[24,0,\"chat-message-creator__members-input\"],[16,\"autofocus\",true],[4,[32,4],[\"input\",[30,0,[\"onFilter\"]]],null],[4,[32,4],[\"keydown\",[30,0,[\"handleKeypress\"]]],null],[4,[32,5],[[30,0,[\"registerFocusFilterAction\"]]],null]],[[\"@value\"],[[30,5]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@members\",\"member\",\"@onSelectMember\",\"@highlightedMember\",\"@filter\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/data/www/htdocs/aihub/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message-creator/members.js",
      "scope": () => [_dIcon.default, _member.default, _truthHelpers.eq, _component2.Input, _modifier.on, _didInsert.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = Members;
});