define("discourse/plugins/chat/discourse/components/chat/message-creator/list-handler", ["exports", "@glimmer/component", "ember-modifier", "discourse/plugins/chat/discourse/components/chat/message-creator/lib/iterate-list", "@ember/component", "@ember/template-factory"], function (_exports, _component, _emberModifier, _iterateList, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ListHandler extends _component.default {
    handleKeydown = (() => (0, _emberModifier.modifier)(element1 => {
      const handler1 = event1 => {
        if (event1.key === "ArrowDown") {
          event1.preventDefault();
          event1.stopPropagation();
          this.args.onHighlight((0, _iterateList.getNext)(this.args.items, this.args.highlightedItem));
        } else if (event1.key === "ArrowUp") {
          event1.preventDefault();
          event1.stopPropagation();
          this.args.onHighlight((0, _iterateList.getPrevious)(this.args.items, this.args.highlightedItem));
        } else if (event1.key === "Enter" && this.args.highlightedItem) {
          event1.preventDefault();
          event1.stopPropagation();
          if (event1.shiftKey && this.args.onShifSelect) {
            this.args.onShifSelect(this.args.highlightedItem);
          } else {
            this.args.onSelect(this.args.highlightedItem);
          }
        }
      };
      element1.addEventListener("keydown", handler1);
      return () => {
        element1.removeEventListener("keydown", handler1);
      };
    }))();
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <span style="display: contents" {{this.handleKeydown}} ...attributes>
          {{yield}}
        </span>
      
    */
    {
      "id": "xSLlqWDW",
      "block": "[[[1,\"\\n    \"],[11,1],[24,5,\"display: contents\"],[17,1],[4,[30,0,[\"handleKeydown\"]],null,null],[12],[1,\"\\n      \"],[18,2,null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
      "moduleName": "/data/www/htdocs/aihub/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message-creator/list-handler.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ListHandler;
});