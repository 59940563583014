define("discourse/plugins/chat/discourse/components/full-page-chat", ["exports", "@glimmer/component", "@ember/helper", "@ember/service", "discourse/plugins/chat/discourse/components/chat-channel", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _service, _chatChannel, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FullPageChat extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#each (array @channel) as |channel|}}
          <ChatChannel @channel={{channel}} @targetMessageId={{@targetMessageId}} />
        {{/each}}
      
    */
    {
      "id": "WGHGbxRE",
      "block": "[[[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[28,[32,0],[[30,1]],null]],null]],null],null,[[[1,\"      \"],[8,[32,1],null,[[\"@channel\",\"@targetMessageId\"],[[30,2],[30,3]]],null],[1,\"\\n\"]],[2]],null],[1,\"  \"]],[\"@channel\",\"channel\",\"@targetMessageId\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/data/www/htdocs/aihub/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/full-page-chat.js",
      "scope": () => [_helper.array, _chatChannel.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = FullPageChat;
});