define("discourse/plugins/chat/discourse/components/chat/thread/header-unread-indicator", ["exports", "@glimmer/component", "@ember/service", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ThreadHeaderUnreadIndicator extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    unreadCountLabel = (() => _discourseI18n.default.t("chat.unread_threads_count", {
      count: this.cappedUnreadCount
    }))();
    get unreadCount() {
      return this.args.channel.threadsManager.unreadThreadCount;
    }
    get showUnreadIndicator() {
      return !this.currentUser.isInDoNotDisturb() && this.unreadCount > 0;
    }
    get cappedUnreadCount() {
      return this.unreadCount > 99 ? "99+" : this.unreadCount;
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.showUnreadIndicator}}
          <div
            class="chat-thread-header-unread-indicator"
            title={{this.unreadCountLabel}}
          >
            <div
              class="chat-thread-header-unread-indicator__number"
            >{{this.cappedUnreadCount}}</div>
          </div>
        {{/if}}
      
    */
    {
      "id": "Bmfn5/WP",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"showUnreadIndicator\"]],[[[1,\"      \"],[10,0],[14,0,\"chat-thread-header-unread-indicator\"],[15,\"title\",[30,0,[\"unreadCountLabel\"]]],[12],[1,\"\\n        \"],[10,0],[14,0,\"chat-thread-header-unread-indicator__number\"],[12],[1,[30,0,[\"cappedUnreadCount\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/data/www/htdocs/aihub/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/thread/header-unread-indicator.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ThreadHeaderUnreadIndicator;
});